import React from "react";
import { EnglishObj, HindiObj } from "../contants";

const Features = ({selected, setSelected}) => {
    return (
        <>

            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center pb-5">
                        <div className="col-md-7 heading-section text-center ftco-animate">
                            <span className="subheading">{selected==="English"?EnglishObj?.welcome:HindiObj?.welcome}</span>
                            <h2>{selected==="English"?EnglishObj?.we_believe:HindiObj?.we_believe}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 services-2 w-100 text-center">
                            <div className="icon icon-1 d-flex align-items-center justify-content-center"><span
                                className="flaticon-water-drop"></span></div>
                            <div className="text">
                                <h4>{selected==="English"?EnglishObj?.water?.name:HindiObj?.water?.name}</h4>
                                <p>{selected==="English"?HindiObj?.water?.desc:HindiObj?.water?.desc}</p>
                            </div>
                        </div>
                        <div className="col-md-3 services-2 w-100 text-center">
                            <div className="icon icon-2 d-flex align-items-center justify-content-center"><span
                                className="flaticon-stethoscope"></span></div>
                            <div className="text">
                                <h4>{selected==="English"?EnglishObj?.medical?.name:HindiObj?.medical?.name}</h4>
                                <p>{selected==="English"?EnglishObj?.medical?.desc:HindiObj?.medical?.desc}</p>
                            </div>
                        </div>
                        <div className="col-md-3 services-2 w-100 text-center">
                            <div className="icon icon-3 d-flex align-items-center justify-content-center"><span className="flaticon-bread"></span>
                            </div>
                            <div className="text">
                                <h4>{selected==="English"?EnglishObj?.food?.name:HindiObj?.food?.name}</h4>
                                <p>{selected==="English"?EnglishObj?.food?.desc:HindiObj?.food?.desc}</p>
                            </div>
                        </div>
                        <div className="col-md-3 services-2 w-100 text-center">
                            <div className="icon icon-4 d-flex align-items-center justify-content-center"><span
                                className="flaticon-university"></span></div>
                            <div className="text">
                                <h4>{selected==="English"?EnglishObj?.education?.name:HindiObj?.education?.name}</h4>
                                <p>{selected==="English"?EnglishObj?.education?.desc:HindiObj?.education?.desc}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Features;