import React from "react";
import { EnglishObj, HindiObj } from "../contants";
const Cards = ({selected, setSelected}) => {
    return (
        <>
            <section className="ftco-section ftco-no-pt ftco-no-pb">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-3 d-flex align-items-stretch">
                            <div className="services">
                                <div className="text text-center bg-secondary">
                                    <h3>{selected==="English"?EnglishObj?.cards1?.text1:HindiObj?.cards1?.text1}<br />{selected==="English"?EnglishObj?.cards1?.text2:HindiObj?.cards1?.text2}</h3>
                                </div>
                                <div className="img border-2" style={{backgroundImage: "url(images/services-1.jpg)"}}>
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="flaticon-volunteer"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex align-items-stretch">
                            <div className="services">
                                <div className="text text-center bg-tertiary">
                                    <h3>{selected==="English"?EnglishObj?.cards2?.text1:HindiObj?.cards2?.text1}<br />{selected==="English"?EnglishObj?.cards2?.text2:HindiObj?.cards2?.text2}</h3>
                                </div>
                                <div className="img border-3" style={{backgroundImage: "url(images/services-2.jpg)"}}>
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="flaticon-piggy-bank"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex align-items-stretch">
                            <div className="services">
                                <div className="text text-center bg-primary">
                                    <h3>{selected==="English"?EnglishObj?.cards3?.text1:HindiObj?.cards3?.text1}<br />{selected==="English"?EnglishObj?.cards3?.text2:HindiObj?.cards3?.text2}</h3>
                                </div>
                                <div className="img border-1" style={{backgroundImage: "url(images/services-3.jpg)"}}>
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="flaticon-donation"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex align-items-stretch">
                            <div className="services">
                                <div className="text text-center bg-quarternary">
                                    <h3>{selected==="English"?EnglishObj?.cards4?.text1:HindiObj?.cards4?.text1}<br />{selected==="English"?EnglishObj?.cards4?.text2:HindiObj?.cards4?.text2}</h3>
                                </div>
                                <div className="img border-4" style={{backgroundImage: "url(images/services-4.jpg)"}}>
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="flaticon-ecological"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Cards;