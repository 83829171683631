import React,{useState} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import MainContent from "../components/About/MainContent";
import Features from "../components/About/Features";
import FooterBar from "../components/FooterBar";

const About = () => {
    const [selected, setSelected] = useState('')
    return (
        <>
         <Header selected={selected} setSelected={setSelected}/>
         <Breadcrumb name="about" selected={selected} setSelected={setSelected}/>
         <MainContent selected={selected} setSelected={setSelected}/>
         <Features selected={selected} setSelected={setSelected}/>
         <FooterBar selected={selected} setSelected={setSelected}/>
        <Footer selected={selected} setSelected={setSelected}/>
        </>
    )
}
export default About;