import React from "react";
import { EnglishObj, HindiObj } from "../contants";

const GalleryMain = ({selected, setSelected}) => {
    return (
        <>
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center pb-5 mb-3">
                        <div className="col-md-7 heading-section text-center ftco-animate">
                            <h2>{selected==="English"?EnglishObj?.our_events:HindiObj?.our_events}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/events-1.png)" }}></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/events-2.png)" }}></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/events-3.png)" }}></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/events-4.png)" }}></div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/events-5.png)" }}></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/events-6.png)" }}></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/events-7.png)" }}></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/events-8.png)" }}></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/events-9.png)" }}></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/events-10.png)" }}></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/events-11.png)" }}></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/events-12.png)" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default GalleryMain;