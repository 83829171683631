import React from "react";
import { HindiObj, EnglishObj } from "../contants";

const Welcome = ({selected, setSelected}) => {
    return (
        <section className="ftco-appointment ftco-section ftco-no-pt ftco-no-pb img">
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-7 wrap-about py-5">
                        <div className="heading-section pr-md-5 pt-md-5">
                            <span className="subheading">{selected==="English"?EnglishObj?.welcome:HindiObj?.welcome}</span>
                            <h2 className="mb-4">{selected==="English"?EnglishObj?.we_here_need:HindiObj?.we_here_need}</h2>
                            <p>{selected==="English"?EnglishObj?.welcome_desc:HindiObj?.welcome_desc}</p>
                        </div>
                        <div className="row my-md-5">
                            <div className="col-md-6 d-flex counter-wrap">
                                <div className="block-18 d-flex">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="flaticon-volunteer"></span>
                                    </div>
                                    <div className="desc">
                                        <div className="text">
                                            <strong className="number" data-number="20">20</strong>
                                        </div>
                                        <div className="text">
                                            <span>{selected==="English"?EnglishObj?.volunteers:HindiObj?.volunteers}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p><a href="contact.html" className="btn btn-secondary btn-outline-secondary">{selected==="English"?EnglishObj?.become_a_volunteer:HindiObj?.become_a_volunteer}</a></p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Welcome