import React from "react";
import { EnglishObj,HindiObj } from "../contants";
const Blog = ({selected, setSelected}) => {
    return (
        <>
            <section className="ftco-section">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-md-6 col-lg-3 d-flex ftco-animate">
                            <div className="blog-entry align-self-stretch">
                                <a href="blog-single.html" className="block-20" style={{ backgroundImage: "url(images/yoga.png)" }}>
                                </a>
                                <div className="text p-4" style={{ minHeight: "445px" }}>
                                    <div className="meta mb-2">
                                        <div><a href="#">{selected==="English"?EnglishObj?.yoga_camp?.date:HindiObj?.yoga_camp?.date}</a></div>
                                    </div>
                                    <h3 className="heading"><a href="#">{selected==="English"?EnglishObj?.yoga_camp?.name:HindiObj?.yoga_camp?.name}</a></h3>
                                    <p>{selected==="English"?EnglishObj?.yoga_camp?.desc:HindiObj?.yoga_camp?.desc}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex ftco-animate">
                            <div className="blog-entry align-self-stretch">
                                <a href="#" className="block-20" style={{ backgroundImage: "url(images/health_camp.png)" }}>
                                </a>
                                <div className="text p-4" style={{ minHeight: "445px" }}>
                                    <div className="meta mb-2">
                                        <div><a href="#">{selected==="English"?EnglishObj?.health_camp?.date:HindiObj?.health_camp?.date}</a></div>
                                    </div>
                                    <h3 className="heading"><a href="#">{selected==="English"?EnglishObj?.health_camp?.name:HindiObj?.health_camp?.name}</a></h3>
                                    <p>{selected==="English"?EnglishObj?.health_camp?.desc:HindiObj?.health_camp?.desc}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex ftco-animate">
                            <div className="blog-entry align-self-stretch">
                                <a href="#" className="block-20" style={{ backgroundImage: "url(images/candle.png)" }}>
                                </a>
                                <div className="text p-4" style={{ minHeight: "445px" }}>
                                    <div className="meta mb-2">
                                        <div><a href="#">{selected==="English"?EnglishObj?.candle_workshop?.date:HindiObj?.candle_workshop?.date}</a></div>
                                    </div>
                                    <h3 className="heading"><a href="#">{selected==="English"?EnglishObj?.candle_workshop?.name:HindiObj?.candle_workshop?.name}</a></h3>
                                    <p>{selected==="English"?EnglishObj?.candle_workshop?.desc:HindiObj?.candle_workshop?.desc}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex ftco-animate">
                            <div className="blog-entry align-self-stretch">
                                <a href="#" className="block-20" style={{ backgroundImage: "url(images/swachhta.png)" }}>
                                </a>
                                <div className="text p-4" style={{ minHeight: "445px" }}>
                                    <div className="meta mb-2">
                                        <div><a href="#">{selected==="English"?EnglishObj?.clean_environment?.date:HindiObj?.clean_environment?.date}</a></div>
                                    </div>
                                    <h3 className="heading"><a href="#">{selected==="English"?EnglishObj?.clean_environment?.name:HindiObj?.clean_environment?.name}</a></h3>
                                    <p>{selected==="English"?EnglishObj?.clean_environment?.desc:HindiObj?.clean_environment?.desc}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Blog;