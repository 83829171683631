import React,{useState} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import FooterBar from "../components/FooterBar";
import GalleryMain from "../components/Gallery";

const Gallery = () => {
    const [selected, setSelected] = useState('English')
    return (
        <>
        <Header selected={selected} setSelected={setSelected}/>
        <Breadcrumb name="gallery" selected={selected} setSelected={setSelected}/>
        <GalleryMain selected={selected} setSelected={setSelected}/>
        <FooterBar selected={selected} setSelected={setSelected}/>
        <Footer selected={selected} setSelected={setSelected}/>
        </>
    )
}
export default Gallery;