import React from "react";
import { EnglishObj,HindiObj } from "../contants";

const DonateUs = ({selected, setSelected}) => {
    return (
        <>

            <section className="ftco-appointment ftco-section ftco-no-pt ftco-no-pb img">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 order-md-last d-flex align-items-stretch">
                            <div className="donation-wrap">
                                <div className="total-donate d-flex align-items-center">
                                    <span className="fa flaticon-cleaning"></span>
                                    <h4>{selected==="English"?EnglishObj?.donation_campaign:HindiObj?.donation_campaign}</h4>
                                </div>
                                <form action="#" className="appointment">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <img src="images/qr.jpeg" width="100%" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-7 wrap-about py-5">
                            <div className="heading-section pr-md-5 pt-md-5">
                                <span className="subheading">{selected==="English"?EnglishObj?.bank_details:HindiObj?.bank_details}</span>
                                <br />
                                <br />
                                <table>
                                    <tr>
                                        <th className="mr-2">{selected==="English"?EnglishObj?.bank_name?.name:HindiObj?.bank_name?.name}</th>
                                        <td>{selected==="English"?EnglishObj?.bank_name?.desc:HindiObj?.bank_name?.desc}</td>
                                    </tr>
                                    <tr>
                                        <th className="mr-2">{selected==="English"?EnglishObj?.account_name?.name:HindiObj?.account_name?.name}</th>
                                        <td>{selected==="English"?EnglishObj?.account_name?.desc:HindiObj?.account_name?.desc}</td>
                                    </tr>
                                    <tr>
                                        <th className="mr-2">{selected==="English"?EnglishObj?.account_no?.name:HindiObj?.account_no?.name}</th>
                                        <td>{selected==="English"?EnglishObj?.account_no?.desc:HindiObj?.account_no?.desc}</td>
                                    </tr>
                                    <tr>
                                        <th className="mr-2">{selected==="English"?EnglishObj?.ifsc_code?.name:HindiObj?.ifsc_code?.name}</th>
                                        <td>{selected==="English"?EnglishObj?.ifsc_code?.desc:HindiObj?.ifsc_code?.desc}</td>
                                    </tr>
                                    <tr>
                                        <th className="mr-2">{selected==="English"?EnglishObj?.bank_address?.name:HindiObj?.bank_address?.name}</th>
                                        <td>{selected==="English"?EnglishObj?.bank_address?.desc:HindiObj?.bank_address?.desc}</td>
                                    </tr>
                                </table>
                                <br />
                                <br />


                                <p>{selected==="English"?EnglishObj?.thanks_text:HindiObj?.thanks_text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default DonateUs