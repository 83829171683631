import React from "react";
import { EnglishObj, HindiObj } from "../contants";

const MainContent = ({selected, setSelected}) => {
    return (
        <>
            <section className="ftco-section ftco-no-pt ftco-no-pb">
                <div className="container">
                    <div className="row d-flex no-gutters">
                        <div className="col-md-6 d-flex order-md-last">
                            <div
                                className="img img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0"
                                style={{backgroundImage:"url(images/about-1.jpg)"}}>
                            </div>
                        </div>
                        <div className="col-md-6 pr-md-5 py-md-5">
                            <div className="heading-section pt-md-5 mb-4">
                                <span className="subheading">{selected==="English"?EnglishObj?.about_us:HindiObj?.about_us}</span>
                                <h2 className="mb-2">{selected==="English"?EnglishObj?.join_us:HindiObj?.join_us}</h2>
                                <p>{selected==="English"?EnglishObj?.desc1:HindiObj?.desc1}</p>
                                <p>{selected==="English"?EnglishObj?.desc2:HindiObj?.desc2}</p>
                                <p><a href="contact.html" className="btn btn-secondary">{selected==="English"?EnglishObj?.become_a_volunteer:HindiObj?.become_a_volunteer}</a></p>
                            </div>
                        </div>
                        <div className="col-md-6 pr-md-5 py-md-5">
                            <div className="heading-section pt-md-5 mb-4">
                                <span className="subheading">{selected==="English"?EnglishObj?.our_philosophy?.name:HindiObj?.our_philosophy?.name}</span>
                                <p>{selected==="English"?EnglishObj?.our_philosophy?.desc:HindiObj?.our_philosophy?.desc}</p>
                            </div>
                            <div className="heading-section pt-md-5 mb-4">
                                <span className="subheading">{selected==="English"?EnglishObj?.our_mission?.name:HindiObj?.our_mission?.name}</span>
                                <p>{selected==="English"?EnglishObj?.our_mission?.desc:HindiObj?.our_mission?.desc}</p>
                            </div>
                            <div className="heading-section pt-md-5 mb-4">
                                <span className="subheading">{selected==="English"?EnglishObj?.our_vision?.name:HindiObj?.our_vision?.name}</span>
                                <p>{selected==="English"?EnglishObj?.our_vision?.desc:HindiObj?.our_vision?.desc}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default MainContent;