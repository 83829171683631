import React from "react";
import { EnglishObj,HindiObj } from "./contants";

const Breadcrumb = (props) => {
    return (
        <>
            <section className="hero-wrap hero-wrap-2" style={{backgroundImage: "url(images/bg_2.jpg)"}}
                data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end">
                        <div className="col-md-9 ftco-animate pb-5">
                            <p className="breadcrumbs mb-2"><span className="mr-2"><a href="/">{props.selected==="English"?EnglishObj?.menu?.home:HindiObj?.menu?.home} <i
                                className="ion-ios-arrow-forward"></i></a></span> <span>{props.selected==="English" && props.name==='about'?EnglishObj?.about_us:props.selected==="English" && props.name==="gallery"?EnglishObj?.menu?.gallery:props.selected==="English" && props.name==="blog"?EnglishObj?.menu?.blog:props.selected==="English" && props.name==="contact"?EnglishObj?.contact_us:props.selected==="English" && props.name==="donate"?EnglishObj?.donate_now:props.selected!=="English" &&props.name==='about'?HindiObj?.about_us:props.selected!=="English" &&props.name==='blog'?HindiObj?.menu?.blog:props.selected!=="English" &&props.name==='gallery'?HindiObj?.menu?.gallery:props.selected!=="English" &&props.name==='contact'?HindiObj?.contact_us:props.selected!=="English" &&props.name==='donate'?HindiObj?.donate_now:''}<i
                                    className="ion-ios-arrow-forward"></i></span></p>
                            <h1 className="mb-0 bread">{props.selected==="English" && props.name==='about'?EnglishObj?.about_us:props.selected==="English" && props.name==="gallery"?EnglishObj?.menu?.gallery:props.selected==="English" && props.name==="blog"?EnglishObj?.menu?.blog:props.selected==="English" && props.name==="contact"?EnglishObj?.contact_us:props.selected==="English" && props.name==="donate"?EnglishObj?.donate_now:props.selected!=="English" &&props.name==='about'?HindiObj?.about_us:props.selected!=="English" &&props.name==='blog'?HindiObj?.menu?.blog:props.selected!=="English" &&props.name==='gallery'?HindiObj?.menu?.gallery:props.selected!=="English" &&props.name==='contact'?HindiObj?.contact_us:props.selected!=="English" &&props.name==='donate'?HindiObj?.donate_now:''}</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Breadcrumb