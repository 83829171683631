import React from "react";
import { EnglishObj, HindiObj } from "../contants";

const Causes=({selected, setSelected})=>{
return(
    <>
    <section className="ftco-section ftco-no-pb">
		<div className="container">
			<div className="row justify-content-center pb-5 mb-3">
				<div className="col-md-7 heading-section text-center ftco-animate">
					<span className="subheading">{selected==="English"?EnglishObj?.our_causes:HindiObj?.our_causes}</span>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6 col-lg-3">
					<div className="causes causes-2 text-center ftco-animate">
						<a href="#" className="img w-100" style={{backgroundImage: "url(images/causes-1.jpg)"}}></a>
						<div className="text p-3" style={{minHeight: "492px"}}>
							<h2><a href="#">{selected==="English"?EnglishObj?.education_literacy?.name:HindiObj?.education_literacy?.name}</a></h2>
							<p>{selected==="English"?EnglishObj?.education_literacy?.desc:HindiObj?.education_literacy?.desc}</p>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-3">
					<div className="causes causes-2 text-center ftco-animate">
						<a href="#" className="img w-100" style={{backgroundImage: "url(images/causes-2.jpg)"}}></a>
						<div className="text p-3" style={{minHeight: "492px"}}>
							<h2><a href="#">{selected==="English"?EnglishObj?.environment_forests?.name:HindiObj?.environment_forests?.name}</a></h2>
							<p>{selected==="English"?EnglishObj?.environment_forests?.desc:HindiObj?.environment_forests?.desc}</p>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-3">
					<div className="causes causes-2 text-center ftco-animate">
						<a href="#" className="img w-100" style={{backgroundImage: "url(images/causes-3.jpg)"}}></a>
						<div className="text p-3" style={{minHeight: "492px"}}>
							<h2><a href="#">{selected==="English"?EnglishObj?.human_rights?.name:HindiObj?.human_rights?.name}</a></h2>
							<p>{selected==="English"?EnglishObj?.human_rights?.desc:HindiObj?.human_rights?.desc}</p>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-3">
					<div className="causes causes-2 text-center ftco-animate">
						<a href="#" className="img w-100" style={{backgroundImage: "url(images/causes-4.jpg)"}}></a>
						<div className="text p-3" style={{minHeight: "492px"}}>
							<h2><a href="#">{selected==="English"?EnglishObj?.women_empowerment?.name:HindiObj?.women_empowerment?.name}</a></h2>
							<p>{selected==="English"?EnglishObj?.women_empowerment?.desc:HindiObj?.women_empowerment?.desc}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    </>
)
}
export default Causes