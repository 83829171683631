export const EnglishObj = {
    "name": "PRACHITI SEVA FOUNDATION",
    "menu":
    {
        "home": "Home",
        "about": "About",
        "gallery": "Gallery",
        "blog": "Blog",
        "contact": "Contact"
    },
    "hindi": "Hindi",
    "english": "English",
    "slide": {
        "slide1": {
            "text1": "Improving awarness for environment",
            "text2": "Get Involved for our Mother Earth"
        },
        "slide2": {
            "text1": "Raising Hope for Women",
            "text2": "Discover Non-Profit Charity Platform for them to evolve"
        },
        "slide3": {
            "text1": "Helping Childrens",
            "text2": "Giving Priority on Education and Literacy"
        }
    },
    "become_a_volunteer": "Become a Volunteer",
    "welcome": "WELCOME TO PRACHITI SEVA FOUNDATION",
    "we_here_need": "We are here to help everyone in need",
    "welcome_desc": "Through our impactful initiatives, we strive to transform lives and contribute to the overall development of communities. With a strong focus on Education & Literacy, Environment & Forests, Food Processing, Health & Family Welfare, and Human Rights, we work tirelessly to make a positive and lasting impact.",
    "volunteers": "Volunteers",
    "cards1":{
        "text1":"Become a",
        "text2":"Volunteer"
    },
    "cards2":{
        "text1":"Quick",
        "text2":"Fundraising"
    },
    "cards3":{
        "text1":"Start",
        "text2":"Donating"
    },
    "cards4":{
        "text1":"Get",
        "text2":"Involved"
    },
    "our_causes": "Our Causes",
    "education_literacy":
    {
        "name": "Education & Literacy",
        "desc": "We provide access to quality education, scholarships, and vocational training to empower students and equip them with the necessary skills for a brighter future. We also organize educational programs, workshops, and mentoring initiatives to promote lifelong learning."
    },
    "environment_forests":
    {
        "name": "Environment & Forests",
        "desc": "Our activities in this sector focus on environmental conservation, sustainable practices, and raising awareness. We organize tree plantation drives, promote waste management and recycling, conduct environmental awareness campaigns, and collaborate with local communities to protect and preserve natural resources."
    },
    "human_rights": {
        "name": "Human Rights",
        "desc": "We actively advocate for human rights, equality, and justice. Our activities include awareness campaigns, workshops, and seminars to educate individuals about their rights, combating discrimination and social injustice, providing legal aid and support to marginalized communities, and collaborating with human rights organizations to promote a just and inclusive society."
    },
    "women_empowerment": {
        "name": "Women Empowerment",
        "desc": "We actively support women to practice activities which we help them be independent. We conduct Seminars to guide them techniques of self earning. Self Help Group Training are conducted by our team of experts to help them grow and earn."
    },
    "our_expert_volunteers": "Our Expert Volunteer",
    "shubhash_jain": "Subhash Sheshrao Jain",
    "chairman": "Chairman",
    "prayas_jain": "Prayas Subhash Jain",
    "president": "President",
    "prachiti_jain": "Prachiti Subhash Jain",
    "vice_president": "Vice President",
    "best_way_to": "Best Way to Make a Difference in the Lives of Others",
    "donate_now": "Donate Now",
    "footer_text": "PRACHITI SEVA FOUNDATION, a dynamic non-governmental organization dedicated to creating sustainable change in Maharashtra.",
    "quick_links": "QUICK LINKS",
    "have_a_questions": "HAVE A QUESTIONS?",
    "copyright": "Copyright © 2023 All rights reserved | Prachiti Seva Foundation",
    "about_us":"About Us",
    "join_us": "Join Us In Creating Change",
    "desc1": "Join us in creating change. Whether you’re a student, professional, or community member, there are various ways to get involved with PRACHITI SEVA FOUNDATION. Volunteer, donate, or partner with us to empower communities, advocate for human rights, improve education, protect the environment, and support sustainable development. Together, we can make a difference in Maharashtra.",
    "desc2": "Our team at PRACHITI SEVA FOUNDATION is a dedicated and passionate group working towards creating positive change in Maharashtra. Led by President Prayas Subhash Jain, Chairman Subhash Sheshrao Jain, and Vice President Prachiti Subhash Jain, we are committed to empowering communities and addressing critical issues. With expertise, enthusiasm, and a shared vision, our team works collaboratively to make a lasting impact and build a better future for all.",
    "our_philosophy": {
        "name": "OUR PHILOSOPHY",
        "desc": "Our philosophy is simple yet powerful: Empower. Transform. Impact. Through our dedication to empowering individuals, transforming communities, and making a positive impact, we strive to create sustainable change in Maharashtra. We believe that by focusing on education, sustainability, and human rights, we can bring about meaningful and lasting transformation that benefits all. Join us in our mission to empower, transform, and make a difference in the lives of individuals and communities."
    },
    "our_mission": {
        "name": "OUR MISSION",
        "desc": "Our mission is to actively engage in social services that focus on youth and women empowerment, health and environment awareness, skills development, education and literacy, food processing, and human rights awareness. We are committed to contributing to nation-building and making every effort to create a positive impact on society."
    },
    "our_vision": {
        "name": "OUR VISION",
        "desc": "Prachiti Seva Foundation’s vision is to build a society where every individual has access to quality education, a sustainable environment, nutritious food, adequate health care and can enjoy their fundamental human rights. We strive to build a future where everyone can live a dignified and empowered life and contribute to the overall development and well-being of their communities."
    },
    "we_believe": "We Believe that We Can Save More Lifes with You",
    "water": {
        "name": "Water",
        "desc": "Awareness about conservation of water and how to reuse them effectively."
    },
    "medical": {
        "name": "Medical",
        "desc": "Raising awareness for diseases with their home remedy cures and conducting health checkups."
    },
    "food": {
        "name": "Food",
        "desc": "Providing food to person in need and providing healthy life style awareness."
    },
    "education": {
        "name": "Education",
        "desc": "Contributing in Education of young minds who deserves the most for growing nation."
    },
    "our_events": "Our Events",
    "yoga_camp": {
        "name": "Yoga Camp",
        "date": "21 July 2023",
        "desc": "Our team organized a Yoga camp for all group enthusiatic people around us. To Live healthy life we do natural exercises and Yoga is one of the best way to feel free, breath fresh air and stay healthy."
    },
    "health_camp": {
        "name": "Health Checkup Camp",
        "date": "15 August 2023",
        "desc": "Our team has organized Free Health Checkup Camps for the betterment of society. We do neglect our health and most of the people doesn't do routine health checkups, so this initiative serves a good platform for them."
    },
    "candle_workshop": {
        "name": "Candle Making Workshop",
        "date": "20 September 2023",
        "desc": "Our team organized candle making workshop to help grow needy womens and empower their strengths. We believe in women empowerment and this initiaive helps them to learn new skill and earn from it by making it business."
    },
    "clean_environment": {
        "name": "Clean Environment Initiative",
        "date": "02 October 2023",
        "desc": "On the occassion Swachhta Divas, Our team encouraged people around us to participate in the clean environment initiative and be integral part of Swachha Bharat Abhiyan. Our team believe Cleaniness is a way towards healthiness."
    },
    "contact_us": "Contact Us",
    "address": {
        "name": "Address",
        "desc": "House no. 481, Angur, Bagicha, Gondia Katangi, Gondia-441614, Maharashtra"
    },
    "phone": "Phone",
    "email": "Email",
    "website": "Website",
    "full_name": "Full Name",
    "email_address": "Email Address",
    "subject": "Subject",
    "message": "Message",
    "send_message": "Send Message",
    "bank_details":"Bank Details",
    "bank_name":{
        "name":"Bank Name",
        "desc":"Punjab National Bank"
    },
    "account_name":{
        "name":"Account Name",
        "desc":"Prachiti Seva Foundation"
    },
    "account_no":{
        "name":"Account Number",
        "desc":"6322002100003577"
    },
    "ifsc_code":{
        "name":"IFSC Code",
        "desc":"PUNB632200"
    },
    "bank_address":{
        "name":"Bank Address",
        "desc":"Kudwa,Gondia"
    },
    "donation_campaign":"Donation campaign are running",
    "thanks_text":"Thank you for your generosity! Your donation empowers youth & women, raises health & environment awareness, promotes education & literacy, enhances food processing, and advocates for human rights. Together, let's create a brighter future! Grateful for your support."
};

export const HindiObj = {
    "name": "प्रचिति सेवा फाउंडेशन",
    "menu":
    {
        "home": "होम",
        "about": "हमारे बारे में",
        "gallery": "गैलरी",
        "blog": "ब्लॉग",
        "contact": "संपर्क"
    },
    "hindi": "हिंदी",
    "english": "अंग्रेज़ी",
    "slide": {
        "slide1": {
            "text1": "पर्यावरण के प्रति जागरूकता में सुधार",
            "text2": "हमारी धरती माँ के लिए शामिल हों"
        },
        "slide2": {
            "text1": "महिलाओं के लिए आशा जगाना",
            "text2": "उनके विकास के लिए गैर-लाभकारी चैरिटी प्लेटफ़ॉर्म खोजें"
        },
        "slide3": {
            "text1": "बच्चों की मदद करना",
            "text2": "शिक्षा एवं साक्षरता को प्राथमिकता देना"
        }
    },
    "become_a_volunteer": "स्वयंसेवक बनें",
    "welcome": "प्रचिति सेवा फाउंडेशन में आपका स्वागत है ",
    "we_here_need": "हम यहां हर जरूरतमंद की मदद करने के लिए हैं",
    "welcome_desc": "अपनी प्रभावशाली पहलों के माध्यम से, हम जीवन में बदलाव लाने और समुदायों के समग्र विकास में योगदान देने का प्रयास करते हैं। शिक्षा और साक्षरता, पर्यावरण और वन, खाद्य प्रसंस्करण, स्वास्थ्य और परिवार कल्याण और मानवाधिकारों पर विशेष ध्यान देने के साथ, हम सकारात्मक और स्थायी प्रभाव डालने के लिए अथक प्रयास करते हैं।",
    "volunteers": "स्वयंसेवक",
    "cards1":{
        "text1":"स्वयंसेवक",
        "text2":"बनें"
    },
    "cards2":{
        "text1":"उगाहने",
        "text2":"उगाहने"
    },
    "cards3":{
        "text1":"दान",
        "text2":"शुरू करें"
    },
    "cards4":{
        "text1":"शामिल",
        "text2":"हो"
    },
    "our_causes": "हमारे कारण",
    "education_literacy": {
        "name": "शिक्षा और साक्षरता",
        "desc": "हम छात्रों को सशक्त बनाने और उन्हें उज्जवल भविष्य के लिए आवश्यक कौशल से लैस करने के लिए गुणवत्तापूर्ण शिक्षा, छात्रवृत्ति और व्यावसायिक प्रशिक्षण तक पहुंच प्रदान करते हैं। हम आजीवन सीखने को बढ़ावा देने के लिए शैक्षिक कार्यक्रम, कार्यशालाएँ और सलाह देने वाली पहल भी आयोजित करते हैं।"
    },
    "environment_forests": {
        "name": "पर्यावरण एवं वन",
        "desc": "इस क्षेत्र में हमारी गतिविधियाँ पर्यावरण संरक्षण, टिकाऊ प्रथाओं और जागरूकता बढ़ाने पर केंद्रित हैं। हम वृक्षारोपण अभियान आयोजित करते हैं, अपशिष्ट प्रबंधन और पुनर्चक्रण को बढ़ावा देते हैं, पर्यावरण जागरूकता अभियान चलाते हैं, और प्राकृतिक संसाधनों की सुरक्षा और संरक्षण के लिए स्थानीय समुदायों के साथ सहयोग करते हैं।"
    },
    "human_rights": {
        "name": "मानव अधिकार",
        "desc": "हम मानवाधिकारों, समानता और न्याय की सक्रिय रूप से वकालत करते हैं। हमारी गतिविधियों में व्यक्तियों को उनके अधिकारों के बारे में शिक्षित करने, भेदभाव और सामाजिक अन्याय का मुकाबला करने, हाशिए पर रहने वाले समुदायों को कानूनी सहायता और समर्थन प्रदान करने और एक न्यायपूर्ण और समावेशी समाज को बढ़ावा देने के लिए मानवाधिकार संगठनों के साथ सहयोग करने के लिए जागरूकता अभियान, कार्यशालाएं और सेमिनार शामिल हैं।"
    },
    "women_empowerment": {
        "name": "महिला सशक्तिकरण",
        "desc": "हम महिलाओं को गतिविधियों का अभ्यास करने में सक्रिय रूप से समर्थन करते हैं जिससे हम उन्हें स्वतंत्र होने में मदद करते हैं। हम उन्हें स्वयं की कमाई की तकनीकों का मार्गदर्शन करने के लिए सेमिनार आयोजित करते हैं। स्वयं सहायता समूह प्रशिक्षण हमारे विशेषज्ञों की टीम द्वारा आयोजित किया जाता है ताकि उन्हें आगे बढ़ने और कमाई करने में मदद मिल सके।"
    },
    "our_expert_volunteers": "हमारे विशेषज्ञ स्वयंसेवक",
    "shubhash_jain": "सुभाष शेषराव जैन",
    "chairman": "अध्यक्ष",
    "prayas_jain": "प्रयास सुभाष जैन",
    "president": "अध्यक्ष",
    "prachiti_jain": "प्रचिति सुभाष जैन",
    "vice_president": "उपाध्यक्ष",
    "best_way_to": "दूसरों के जीवन में बदलाव लाने का सबसे अच्छा तरीका",
    "donate_now": "अभी दान कीजिए",
    "footer_text": "प्रचिति सेवा फाउंडेशन एक गतिशील गैर-सरकारी संगठन है जो महाराष्ट्र में स्थायी परिवर्तन लाने के लिए समर्पित है।",
    "quick_links": "त्वरित सम्पक",
    "have_a_questions": "क्या आपके पास कोई प्रश्न है?",
    "copyright": "कॉपीराइट © 2023 सर्वाधिकार सुरक्षित | प्रचिति सेवा फाउंडेशन",
    "about_us":"हमारे बारे में",
    "join_us": "परिवर्तन लाने में हमसे जुड़ें",
    "desc1": "परिवर्तन लाने में हमारे साथ जुड़ें। चाहे आप छात्र हों, पेशेवर हों या समुदाय के सदस्य हों, प्रचिति सेवा फाउंडेशन से जुड़ने के कई तरीके हैं। समुदायों को सशक्त बनाने, मानवाधिकारों की वकालत करने, शिक्षा में सुधार करने, पर्यावरण की रक्षा करने और सतत विकास का समर्थन करने के लिए स्वयंसेवक बनें, दान दें या हमारे साथ साझेदारी करें। हम सब मिलकर महाराष्ट्र में बदलाव ला सकते हैं।",
    "desc2": "प्रचिति सेवा फाउंडेशन में हमारी टीम एक समर्पित और भावुक समूह है जो महाराष्ट्र में सकारात्मक बदलाव लाने की दिशा में काम कर रही है। प्रयास के अध्यक्ष सुभाष जैन, अध्यक्ष सुभाष शेषराव जैन और उपाध्यक्ष प्रचिति सुभाष जैन के नेतृत्व में, हम समुदायों को सशक्त बनाने और महत्वपूर्ण मुद्दों को संबोधित करने के लिए प्रतिबद्ध हैं। विशेषज्ञता, उत्साह और साझा दृष्टिकोण के साथ, हमारी टीम स्थायी प्रभाव डालने और सभी के लिए बेहतर भविष्य बनाने के लिए सहयोगात्मक रूप से काम करती है।",
    "our_philosophy": {
        "name": "हमारा दर्शन",
        "desc": "हमारा दर्शन सरल लेकिन शक्तिशाली है: सशक्त बनाना। परिवर्तन. प्रभाव। व्यक्तियों को सशक्त बनाने, समुदायों को बदलने और सकारात्मक प्रभाव डालने के अपने समर्पण के माध्यम से, हम महाराष्ट्र में स्थायी परिवर्तन लाने का प्रयास करते हैं। हमारा मानना ​​है कि शिक्षा, स्थिरता और मानवाधिकारों पर ध्यान केंद्रित करके, हम सार्थक और स्थायी परिवर्तन ला सकते हैं जिससे सभी को लाभ होगा। व्यक्तियों और समुदायों को सशक्त बनाने, बदलने और उनके जीवन में बदलाव लाने के हमारे मिशन में हमसे जुड़ें।"
    },
    "our_mission": {
        "name": "हमारा विशेष कार्य",
        "desc": "हमारा मिशन उन सामाजिक सेवाओं में सक्रिय रूप से शामिल होना है जो युवा और महिला सशक्तिकरण, स्वास्थ्य और पर्यावरण जागरूकता, कौशल विकास, शिक्षा और साक्षरता, खाद्य प्रसंस्करण और मानवाधिकार जागरूकता पर ध्यान केंद्रित करती हैं। हम राष्ट्र निर्माण में योगदान देने और समाज पर सकारात्मक प्रभाव डालने के लिए हर संभव प्रयास करने के लिए प्रतिबद्ध हैं।"
    },
    "our_vision": {
        "name": "हमारा नज़रिया",
        "desc": "प्रचिति सेवा फाउंडेशन का दृष्टिकोण एक ऐसे समाज का निर्माण करना है जहां प्रत्येक व्यक्ति को गुणवत्तापूर्ण शिक्षा, टिकाऊ वातावरण, पौष्टिक भोजन, पर्याप्त स्वास्थ्य देखभाल उपलब्ध हो और वह अपने मौलिक मानवाधिकारों का आनंद ले सके। हम एक ऐसा भविष्य बनाने का प्रयास करते हैं जहां हर कोई सम्मानजनक और सशक्त जीवन जी सके और अपने समुदायों के समग्र विकास और कल्याण में योगदान दे सके।"
    },
    "we_believe": "हमें विश्वास है कि हम आपके साथ और अधिक जिंदगियाँ बचा सकते हैं",
    "water": {
        "name": "पानी",
        "desc": "जल के संरक्षण और उनका प्रभावी ढंग से पुन: उपयोग करने के बारे में जागरूकता।"
    },
    "medical": {
        "name": "चिकित्सा",
        "desc": "घरेलू उपचार से बीमारियों के प्रति जागरूकता बढ़ाना और स्वास्थ्य जांच कराना।"
    },
    "food": {
        "name": "खाना",
        "desc": "जरूरतमंद व्यक्ति को भोजन उपलब्ध कराना और स्वस्थ जीवन शैली के बारे में जागरूकता प्रदान करना।"
    },
    "education": {
        "name": "शिक्षा",
        "desc": "युवा दिमागों की शिक्षा में योगदान देना जो बढ़ते राष्ट्र के लिए सबसे अधिक योग्य हैं।"
    },
    "our_events": "हमारे आयोजन",
    "yoga_camp": {
        "name": "हमारे आयोजन",
        "date": "21 जुलाई 2023",
        "desc": "हमारी टीम ने हमारे आसपास के सभी उत्साही लोगों के लिए एक योग शिविर का आयोजन किया। स्वस्थ जीवन जीने के लिए हम प्राकृतिक व्यायाम करते हैं और योग स्वतंत्र महसूस करने, ताजी हवा में सांस लेने और स्वस्थ रहने का सबसे अच्छा तरीका है।"
    },
    "health_camp": {
        "name": "स्वास्थ्य जांच शिविर",
        "date": "15 अगस्त 2023",
        "desc": "हमारी टीम ने समाज की भलाई के लिए निःशुल्क स्वास्थ्य जांच शिविर का आयोजन किया है। हम अपने स्वास्थ्य की उपेक्षा करते हैं और अधिकांश लोग नियमित स्वास्थ्य जांच नहीं कराते हैं, इसलिए यह पहल उनके लिए एक अच्छा मंच प्रदान करती है।"
    },
    "candle_workshop": {
        "name": "मोमबत्ती बनाने की कार्यशाला",
        "date": "20 सितंबर 2023",
        "desc": "हमारी टीम ने जरूरतमंद महिलाओं को आगे बढ़ने और उनकी शक्तियों को सशक्त बनाने में मदद करने के लिए मोमबत्ती बनाने की कार्यशाला का आयोजन किया। हम महिला सशक्तिकरण में विश्वास करते हैं और यह पहल उन्हें नए कौशल सीखने और इसे व्यवसाय बनाकर कमाई करने में मदद करती है।"
    },
    "clean_environment": {
        "name": "स्वच्छ पर्यावरण पहल",
        "date": "02 अक्टूबर 2023",
        "desc": "स्वच्छता दिवस के अवसर पर, हमारी टीम ने अपने आस-पास के लोगों को स्वच्छ पर्यावरण पहल में भाग लेने और स्वच्छ भारत अभियान का अभिन्न अंग बनने के लिए प्रोत्साहित किया। हमारी टीम का मानना ​​है कि स्वच्छता स्वस्थता की ओर जाने का एक तरीका है।"
    },
    "contact_us": "संपर्क करें",
    "address": {
        "name": "पता",
        "desc": "मकान नंबर। 481, अंगुर, बगीचा, गोंदिया कटंगी, गोंदिया-441614, महाराष्ट्र"
    },
    "phone": "फ़ोन नंबर",
    "email": "ईमेल",
    "website": "वेबसाइट",
    "full_name": "पूरा नाम",
    "email_address": "मेल पता",
    "subject": "विषय",
    "message": "संदेश",
    "send_message": "मेसेज भेजें",
    "bank_details":"बैंक विवरण",
    "bank_name":{
        "name":"बैंक का नाम",
        "desc":"पंजाब नेशनल बैंक"
    },
    "account_name":{
        "name":"खाता नाम",
        "desc":"प्रचिति सेवा फाउंडेशन"
    },
    "account_no":{
        "name":"खाता संख्या",
        "desc":"6322002100003577"
    },
    "ifsc_code":{
        "name":"आईएफएससी कोड",
        "desc":"PUNB632200"
    },
    "bank_address":{
        "name":"बैंक पता",
        "desc":"कुड़वा,गोंदिया"
    },
    "donation_campaign":"दान अभियान चल रहे है",
    "thanks_text":"उदारता के लिए आपका धन्यवाद! आपका दान युवाओं और महिलाओं को सशक्त बनाता है, स्वास्थ्य और पर्यावरण के बारे में जागरूकता बढ़ाता है, शिक्षा और साक्षरता को बढ़ावा देता है, खाद्य प्रसंस्करण को बढ़ाता है और मानवाधिकारों की वकालत करता है। आइए मिलकर एक उज्जवल भविष्य बनाएं! आपके समर्थन के लिए आभारी हूं."
};


