import React,{useState} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import Blog from "../components/Blog";

const Blogs = () => {
    const [selected, setSelected] = useState('English')
    return (
        <>
        <Header selected={selected} setSelected={setSelected}/>
        <Breadcrumb name="blog"selected={selected} setSelected={setSelected}/>
        <Blog selected={selected} setSelected={setSelected}/>
        <Footer selected={selected} setSelected={setSelected}/>
        </>
    )
}
export default Blogs;