import React from "react";
import { EnglishObj, HindiObj } from "./contants";

const FooterBar = ({selected, setSelected}) => {
    return (
        <>
            <section className="ftco-hireme bg-secondary">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-8 col-lg-8 d-flex align-items-center">
                            <div className="w-100">
                                <h2>{selected==="English"?EnglishObj?.best_way_to:HindiObj?.best_way_to}</h2>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 d-flex align-items-center justify-content-end">
                            <p className="mb-0"><a href="/donate" className="btn btn-primary py-3 px-4">{selected==="English"?EnglishObj?.donate_now:HindiObj?.donate_now}</a></p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default FooterBar;