import React from "react";
import { EnglishObj, HindiObj } from "./contants";

const Footer = ({selected, setSelected}) => {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 mb-4 mb-md-0">
                            <img src="images/logo_dark-removebg-preview.png" style={{ width: "250px", marginBottom: "20px" }} />
                            <p>{selected==="English"?EnglishObj?.footer_text:HindiObj?.footer_text}</p>
                            <ul className="ftco-footer-social p-0">
                                <li className="ftco-animate"><a href="https://api.whatsapp.com/send/?phone=%2B919823690190" data-toggle="tooltip" data-placement="top"
                                    title="Whatsapp"><span className="fa fa-whatsapp"></span></a></li>
                                <li className="ftco-animate"><a href="https://www.facebook.com/profile.php?id=61551398094983&mibextid=nW3QTL" data-toggle="tooltip" data-placement="top"
                                    title="Facebook"><span className="fa fa-facebook"></span></a></li>
                            </ul>
                            <p><a href="/donate" className="btn btn-quarternary">{selected==="English"?EnglishObj?.donate_now:HindiObj?.donate_now}</a></p>
                        </div>
                        <div className="col-md-6 col-lg-4 pl-lg-5 mb-4 mb-md-0">
                            <h2 className="footer-heading">{selected==="English"?EnglishObj?.quick_links:HindiObj?.quick_links}</h2>
                            <ul className="list-unstyled">
                                <li><a href="/" className="py-2 d-block">{selected==="English"?EnglishObj?.menu?.home:HindiObj?.menu?.home}</a></li>
                                <li><a href="/about" className="py-2 d-block">{selected==="English"?EnglishObj?.menu?.about:HindiObj?.menu?.about}</a></li>
                                <li><a href="/contact" className="py-2 d-block">{selected==="English"?EnglishObj?.menu?.contact:HindiObj?.menu?.contact}</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-md-0">
                            <h2 className="footer-heading">{selected==="English"?EnglishObj?.have_a_questions:HindiObj?.have_a_questions}</h2>
                            <div className="block-23 mb-3">
                                <ul>
                                    <li><span className="icon fa fa-map"></span><span className="text">{selected==="English"?EnglishObj?.address?.desc:HindiObj?.address?.desc}</span></li>
                                    <li><a href="#"><span className="icon fa fa-phone"></span><span className="text">+91
                                        9823690190</span></a></li>
                                    <li><a href="#"><span className="icon fa fa-paper-plane"></span><span className="text">
                                        contact@prachitisevafoundation.org</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12 text-center">

                            <p className="copyright">
                                Copyright &copy;
                                {new Date().getFullYear()} All rights reserved | Prachiti Seva
                                Foundation
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <div id="ftco-loader" className="show fullscreen"><svg className="circular" width="48px" height="48px">
                <circle className="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" />
                <circle className="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10"
                    stroke="#F96D00" />
            </svg></div> */}
        </>
    )
}
export default Footer;