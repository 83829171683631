import React from "react";
import { EnglishObj, HindiObj } from "../contants";

const Volunteer = ({selected, setSelected}) => {
    return (
        <>
            <section className="ftco-section ftco-no-pt">
                <div className="container">
                    <div className="row justify-content-center pb-5 mb-3">
                        <div className="col-md-7 heading-section text-center ftco-animate">
                            <span className="subheading">{selected==="English"?EnglishObj?.volunteers:HindiObj?.volunteers}</span>
                            <h2>{selected==="English"?EnglishObj?.our_expert_volunteers:HindiObj?.our_expert_volunteers}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/shubhash.jpg)" }}></div>
                                <div className="text text-1">
                                    <h3>{selected==="English"?EnglishObj?.shubhash_jain:HindiObj?.shubhash_jain}</h3>
                                    <span>{selected==="English"?EnglishObj?.chairman:HindiObj?.chairman}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/prayas.jpg)" }}></div>
                                <div className="text text-2">
                                    <h3>{selected==="English"?EnglishObj?.prayas_jain:HindiObj?.prayas_jain}</h3>
                                    <span>{selected==="English"?EnglishObj?.president:HindiObj?.president}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="volunteer">
                                <div className="img" style={{ backgroundImage: "url(images/prachitee.jpg)" }}></div>
                                <div className="text text-3">
                                    <h3>{selected==="English"?EnglishObj?.prachiti_jain:HindiObj?.prachiti_jain}</h3>
                                    <span>{selected==="English"?EnglishObj?.vice_president:HindiObj?.vice_president}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default Volunteer;