import React from "react";
import { HindiObj, EnglishObj } from "../contants";

const MainSlide = ({selected, setSelected}) => {
	return (
		<section className="hero-wrap js-fullheight">
			<div className="home-slider js-fullheight owl-carousel">
				<div className="slider-item js-fullheight" style={{ backgroundImage: `url(images/environment.jpg)` }}>
					<div className="overlay-1"></div>
					<div className="overlay-2"></div>
					<div className="overlay-3"></div>
					<div className="overlay-4"></div>
					<div className="container">
						<div className="row no-gutters slider-text js-fullheight align-items-center">
							<div className="col-md-10 col-lg-7 ftco-animate">
								<div className="text w-100">
									<h2>{selected==="English"?EnglishObj?.slide?.slide1?.text1:HindiObj?.slide?.slide1?.text1}</h2>
									<h1 className="mb-3">{selected==="English"?EnglishObj?.slide?.slide1?.text2:HindiObj?.slide?.slide1?.text2}</h1>
									<div className="d-flex meta">
										<div className="">
											<p className="mb-0"><a href="/contact" className="btn btn-secondary py-3 px-2 px-md-4">{selected==="English"?EnglishObj?.become_a_volunteer:HindiObj?.become_a_volunteer}</a></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="slider-item js-fullheight" style={{ backgroundImage: `url(images/women.jpg)` }}>
					<div className="overlay-1"></div>
					<div className="overlay-2"></div>
					<div className="overlay-3"></div>
					<div className="overlay-4"></div>
					<div className="container">
						<div className="row no-gutters slider-text js-fullheight align-items-center">
							<div className="col-md-10 col-lg-7 ftco-animate">
								<div className="text w-100">
									<h2>{selected==="English"?EnglishObj?.slide?.slide2?.text1:HindiObj?.slide?.slide2?.text1}</h2>
									<h1 className="mb-3">{selected==="English"?EnglishObj?.slide?.slide2?.text2:HindiObj?.slide?.slide2?.text2}</h1>
									<div className="d-flex meta">
										<div className="">
											<p className="mb-0"><a href="/contact" className="btn btn-secondary py-3 px-2 px-md-4">{selected==="English"?EnglishObj?.become_a_volunteer:HindiObj?.become_a_volunteer}</a></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="slider-item js-fullheight" style={{ backgroundImage: `url(images/bg_2.jpg)` }}>
					<div className="overlay-1"></div>
					<div className="overlay-2"></div>
					<div className="overlay-3"></div>
					<div className="overlay-4"></div>
					<div className="container">
						<div className="row no-gutters slider-text js-fullheight align-items-center">
							<div className="col-md-10 col-lg-7 ftco-animate">
								<div className="text w-100">
									<h2>{selected==="English"?EnglishObj?.slide?.slide3?.text1:HindiObj?.slide?.slide3?.text1}</h2>
									<h1 className="mb-3">{selected==="English"?EnglishObj?.slide?.slide3?.text2:HindiObj?.slide?.slide3?.text2}</h1>
									<div className="d-flex meta">
										<div className="">
											<p className="mb-0"><a href="/contact" className="btn btn-secondary py-3 px-2 px-md-4">{selected==="English"?EnglishObj?.become_a_volunteer:HindiObj?.become_a_volunteer}</a></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
export default MainSlide;