import React from "react";
import { EnglishObj,HindiObj } from "../contants";
import $ from "jquery";

const ContactUs = ({selected, setSelected})=>{
	// const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const form = $(e.target);
    //     $.ajax({
    //         type: "POST",
    //         url: form.attr("action"),
    //         data: form.serialize(),
    //         // success(data) {
    //         //     setResult(data);
    //         // },
    //     });
    // };
    return(
        <>
        <section className="ftco-section">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-12">
					<div className="wrapper">
						<div className="row mb-5">
							<div className="col-md-3">
								<div className="dbox w-100 text-center">
									<div className="icon bg-primary d-flex align-items-center justify-content-center">
										<span className="fa fa-map-marker"></span>
									</div>
									<div className="text">
										<p><span>{selected==="English"?EnglishObj?.address?.name:HindiObj?.address?.name}:</span> {selected==="English"?EnglishObj?.address?.desc:HindiObj?.address?.desc}</p>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="dbox w-100 text-center">
									<div className="icon bg-secondary d-flex align-items-center justify-content-center">
										<span className="fa fa-phone"></span>
									</div>
									<div className="text">
										<p><span>{selected==="English"?EnglishObj?.phone:HindiObj?.phone}:</span> <a href="tel://1234567920">+91 9823690190</a></p>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="dbox w-100 text-center">
									<div className="icon bg-tertiary d-flex align-items-center justify-content-center">
										<span className="fa fa-paper-plane"></span>
									</div>
									<div className="text">
										<p><span>{selected==="English"?EnglishObj?.email:HindiObj?.email}:</span> <a
												href="mailto:info@yoursite.com">contact@prachitisevafoundation.org</a>
										</p>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="dbox w-100 text-center">
									<div className="icon bg-quarternary d-flex align-items-center justify-content-center">
										<span className="fa fa-globe"></span>
									</div>
									<div className="text">
										<p><span>{selected==="English"?EnglishObj?.website:HindiObj?.website}</span> <a href="#">prachitisevafoundation.org</a></p>
									</div>
								</div>
							</div>
						</div>
						<div className="row no-gutters">
							<div className="col-md-7">
								<div className="contact-wrap w-100 p-md-5 p-4">
									<h3 className="mb-4">{selected==="English"?EnglishObj?.contact_us:HindiObj?.contact_us}</h3>
									<form  action="forms/contact.php"  method="POST" id="contactForm" name="contactForm" className="contactForm">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<label className="label" for="name">{selected==="English"?EnglishObj?.full_name:HindiObj?.full_name}</label>
													<input type="text" className="form-control" name="name" id="name" required
														placeholder={selected==="English"?EnglishObj?.full_name:HindiObj?.full_name}/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label className="label" for="email">{selected==="English"?EnglishObj?.email_address:HindiObj?.email_address}</label>
													<input type="email" className="form-control" name="email" id="email" required
														placeholder={selected==="English"?EnglishObj?.email_address:HindiObj?.email_address}/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label className="label" for="subject">{selected==="English"?EnglishObj?.phone:HindiObj?.phone}</label>
													<input type="text" className="form-control" name="phone" id="phone" required maxlength="12"
														placeholder={selected==="English"?EnglishObj?.phone:HindiObj?.phone}/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label className="label" for="subject">{selected==="English"?EnglishObj?.subject:HindiObj?.subject}</label>
													<input type="text" className="form-control" name="subject" required id="subject"
														placeholder={selected==="English"?EnglishObj?.subject:HindiObj?.subject} />
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label className="label" for="#">{selected==="English"?EnglishObj?.message:HindiObj?.message}</label>
													<textarea name="message" className="form-control" id="message" cols="30"
														rows="4" placeholder={selected==="English"?EnglishObj?.message:HindiObj?.message}></textarea>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<input type="submit" value={selected==="English"?EnglishObj?.send_message:HindiObj?.send_message} className="btn btn-primary"/>
													<div className="submitting"></div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div className="col-md-5 d-flex align-items-stretch">
								<div className="info-wrap w-100 p-5 img" style={{backgroundImage: "url(images/contact.png)"}}>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12">

					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1023.1689488116542!2d80.19540987872085!3d21.480259934104147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2bac0fe804ec65%3A0xc4c8c17fa28db9e4!2sAngur%20Bagicha%20Rd%2C%20Teachers%20Colony%2C%20Gondia%2C%20Maharashtra%20441614!5e0!3m2!1sen!2sin!4v1696867486859!5m2!1sen!2sin"
						width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"></iframe>
				</div>
			</div>
		</div>
	</section>
        </>
    )
}
export default ContactUs;