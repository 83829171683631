import React,{useState} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import DonateUs from "../components/DonateUs";

const Donate = () => {
    const [selected, setSelected] = useState('')
    return (
        <>
        <Header selected={selected} setSelected={setSelected}/>
        <Breadcrumb name="donate" selected={selected} setSelected={setSelected}/>
        <DonateUs selected={selected} setSelected={setSelected}/>
        <Footer selected={selected} setSelected={setSelected}/>
        </>
    )
}
export default Donate;