import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Donate from "./pages/Donate";
import { useEffect } from "react";


function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

function App() {
  useEffect(() => {
    loadScript('https://prachitisevafoundation.org/js/main.js')
    loadScript('https://prachitisevafoundation.org/js/jquery.min.js')
    loadScript('https://prachitisevafoundation.org/js/jquery-migrate-3.0.1.min.js')
    loadScript('https://prachitisevafoundation.org/js/popper.min.js')
    loadScript('https://prachitisevafoundation.org/js/bootstrap.min.js')
    loadScript('https://prachitisevafoundation.org/js/jquery.easing.1.3.js')
    loadScript('https://prachitisevafoundation.org/js/jquery.waypoints.min.js')
    loadScript('https://prachitisevafoundation.org/js/jquery.stellar.min.js')
    loadScript('https://prachitisevafoundation.org/js/jquery.animateNumber.min.js')
    loadScript('https://prachitisevafoundation.org/js/bootstrap-datepicker.js')
    loadScript('https://prachitisevafoundation.org/js/jquery.timepicker.min.js')
    loadScript('https://prachitisevafoundation.org/js/owl.carousel.min.js')
    loadScript('https://prachitisevafoundation.org/js/jquery.magnific-popup.min.js')
    loadScript('https://prachitisevafoundation.org/js/scrollax.min.js')
  }, [])
  return (
    <BrowserRouter forceRefresh>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<About />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="donate" element={<Donate />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
