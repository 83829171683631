import React, { useState, useEffect } from "react";
import { HindiObj, EnglishObj } from "./contants";

const Header = ({selected, setSelected}) => {
	console.log(selected, localStorage.getItem('language'))
	useEffect(() => {
		if (localStorage.getItem('language') === null) {
			localStorage.setItem('language', 'English');
			setSelected('English')
		}
		else
		{
			setSelected(localStorage.getItem('language'))
		}
	}, [])
	return (
		<>
			<div className="wrap">
				<div className="container">
					<div className="row">
						<div className="col-md-6 d-flex align-items-center">
							<p className="mb-0 phone pl-md-2">
								<a href="#" className="mr-2"><span className="fa fa-phone mr-1"></span> +91 9823690190</a>
								<a href="#"><span className="fa fa-paper-plane mr-1"></span> contact@prachitisevafoundation.org</a>
							</p>
						</div>
						<div className="col-md-6 d-flex justify-content-md-end">
							<div className="social-media">
								<p className="mb-0 d-flex">
									<a href="https://www.facebook.com/profile.php?id=61551398094983&mibextid=nW3QTL" target="_blank" className="d-flex align-items-center justify-content-center"><span
										className="fa fa-facebook"><i className="sr-only">Facebook</i></span></a>
									<a href="https://api.whatsapp.com/send/?phone=%2B919823690190" target="_blank" className="d-flex align-items-center justify-content-center"><span
										className="fa fa-whatsapp"><i className="sr-only">Whatsapp</i></span></a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
				<div className="container">
					<a className="navbar-brand" href="/"><img src="images/logo_dark-removebg-preview.png"
						style={{ width: "150px" }} /></a>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
						aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="oi oi-menu"></span> Menu
					</button>

					<div className="collapse navbar-collapse" id="ftco-nav">
						<ul className="navbar-nav ml-auto">
							<li className={`nav-item ${window.location.pathname === "/" ? 'active' : ''}`}><a href="/" className="nav-link">{selected === "English" ? EnglishObj?.menu?.home : HindiObj?.menu?.home}</a></li>
							<li className={`nav-item ${window.location.pathname === "/about" ? 'active' : ''}`}><a href="/about" className="nav-link">{selected === "English" ? EnglishObj?.menu?.about : HindiObj?.menu?.about}</a></li>
							<li className={`nav-item ${window.location.pathname === "/gallery" ? 'active' : ''}`}><a href="/gallery" className="nav-link">{selected === "English" ? EnglishObj?.menu?.gallery : HindiObj?.menu?.gallery}</a></li>
							<li className={`nav-item ${window.location.pathname === "/blog" ? 'active' : ''}`}><a href="/blogs" className="nav-link">{selected === "English" ? EnglishObj?.menu?.blog : HindiObj?.menu?.blog}</a></li>
							<li className={`nav-item ${window.location.pathname === "/contact" ? 'active' : ''}`}><a href="/contact" className="nav-link">{selected === "English" ? EnglishObj?.menu?.contact : HindiObj?.menu?.contact}</a></li>
							<li className="nav-item" style={{ display: 'flex', alignItems: 'center' }}><select value={selected} onChange={(e) => { setSelected(e.target.value); localStorage.setItem('language', e.target.value) }}><option value={'English'}>{selected === "English" ? EnglishObj?.english : HindiObj?.english}</option><option value={'Hindi'}>{selected === "English" ? EnglishObj?.hindi : HindiObj?.hindi}</option></select></li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	)
}
export default Header;